//系统设置相关
import request from "@/utils/request";

//分配物理卡
export const assignPCard = (data) =>
request({
  url: "/card/assignPCard",
  method: "post",
  data
});
//激活卡
export const addContact = (id) =>
request({
  url: `/card/activeCard/${id}`,
  method: "post",
  data,
});
///用户银行卡列表
export const userCards = () =>
request({
  url: "/card/userCards",
  method: "GET",
});

//创建虚拟卡
export const getVCard = (data) =>
  request({
    url: `/card/getVCard`,
    method: "post",
    data
  });
  
 // 锁定卡-解锁卡
  export const opCard = (data) =>
  request({
    url: "/card/opCard",
    method: "post",
    data
  });

  //设置银行卡密码
  export const setPin = (data) =>
  request({
    url: "/card/setPin",
    method: "post",
    data
  });
  // 银行卡费用
  export const cardFee = () =>
  request({
    url: "/card/cardFee",
    method: "GET",
  });
  // 支付币种
  export const cardFeeCoins = () =>
  request({
    url: "/card/cardFeeCoins",
    method: "GET",
  });